import React from 'react';
import Layout from '../components/Layout/layout';
import SEO from '../components/seo';
import ThankYou from '../components/ConversionPath/demo-requested';

export default function ReferralThankYouPage() {
  return (
    <Layout>
      <SEO title="Thank you - your request has been received | SpotOn " />
      <ThankYou
        withName={false}
        thankYouMessage="A SpotOn representative will connect with your referral shortly!"
        thankYouSubheader="If you have any additional questions, please call us at"
      />
    </Layout>
  );
}
